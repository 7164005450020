import React from "react";
import AosFatos from "../images/Logo Aos Fatos.png"
import FatoOuFake from "../images/header-fato-ou-fake.webp"
import Lupa from "../images/logo-lupa.png"

export default function Card(props) {

    /*Definição do estilo e imagem a aparecer*/
    let style = "ease-in-out duration-300 text-texto-400 font-bold py-2 mb-4 px-4 rounded bg-[#000000]"
    let image = null
    

    if (props.checador && props.checador.includes("Aos Fatos")) {
        image = AosFatos
        style = "bg-gradient-to-r from-[#e0c551] to-[#F1924F] ease-in-out duration-300 text-texto-400 font-bold py-2 mb-4 px-4 rounded"
    }
    else if (props.checador && props.checador.includes("Lupa")) {
        image = Lupa
        style = "bg-gradient-to-r from-[#de383b] to-[#e58c50] ease-in-out duration-300 text-texto-400 font-bold py-2 mb-4 px-4 rounded"
    }
    else if (props.checador && props.checador.includes("Fato ou Fake")) {
        image = FatoOuFake
        style = "bg-gradient-to-r from-[#368B59] to-[#A1E100] ease-in-out duration-300 text-texto-400 font-bold py-2 mb-4 px-4 rounded"
    }

    return(
        <div className="md:my-0 my-4">

            <a href={props.link} target="blank">
            <div className="ease-in-out duration-300 max-w-sm rounded overflow-hidden shadow-lg bg-white hover:scale-105">

                <img className="w-full" src={image} alt="Logo checador"/>

                <div className="px-6 py-4">
                    <div className="font-bold text-grey-400 text-xl mb-2">{props.texto}</div>
                    <p className="text-slate-800 text-base">
                    {props.data}
                    </p>
                </div>
                
                <div className="px-6 pt-2 pb-2">
                    <a href={props.link} target="blank">
                        <button className={style}>
                            {props.checador}
                        </button>
                    </a>
                </div>
                
            </div>
            </a>

        </div>
    );
}